export const texts:any = {
    bosnian: {
        //GENERAL
        readMore: "Kliknite na karticu da pročitate više!",
        successSubmit: "Poruka uspješno poslana!",

        //HOME PAGE
        homeButton: "POČETNA",
        aboutButton: "O NAMA",
        aboutButton1: "O Kompaniji",
        aboutButton2: "Misija i Vizija",
        aboutButton3: "Naši Rezultati",
        divisionsButton: "NAŠE DIVIZIJE",
        allDivisionsButton: "SVE DIVIZIJE",
        divisionsButton1: "Agrar",
        divisionsButton2: "Finansije",
        divisionsButton3: "Logistika",
        contactButton: "KONTAKT",
        contactButton1: "Kontaktirajte Nas",
        contactButton2: "Lokacija",
        langButton: "JEZIK",
        button: "English",
        aboutHeading: "O NAŠOJ KOMPANIJI",
        aboutText: "Od svog osnivanja 2006. godine, posvećeni smo pružanju najkvalitetnijih proizvoda našim kupcima. Referentna lista naših partnera i kupaca kao i statistički pokazatelji, najbolji su dokaz činjenici da je OKTUM d.o.o. jedna od vodećih kompanija u Bosni i Hercegovini i regiji, u oblastima koje se nalaze u portfoliu naše firme.",
        homePageAboutButton: "VIŠE O NAMA",
        homePageDivisions: "NAŠE DIVIZIJE",
        homePageDivisionsHeading: "DIVIZIJE",
        homePageDivisionsText: "Naš primarni sektor je trgovina na veliko žitaricama, pored primarnog sektora, proširili smo se na još dva - finansije i transport.",
        homePageDivisionsButton: "SVE DIVIZIJE",
        homePageAgrarHeading: "AGRAR",
        homePageAgrarText: "Nudimo širok asortiman žitarica, kako bi mogli ispuniti različite potrebe naših kupaca.",
        homePageFinanceHeading: "FINANSIJE",
        homePageFinanceText: "Finansijski sektor pruža rješenja za financiranje našim klijentima, olakšavajući im kupovinu naših proizvoda.",
        homePageLogisticsHeading: "LOGISTIKA",
        homePageLogisticsText: "Naš transportni sektor ima desetine vlastitih kamiona, osiguravajući da se naši proizvodi bezbedno i efikasno isporučuju kupcima širom zemlje.",
        homePageContactHeading: "KONTAKTIRAJTE NAS",
        homePageContactField1: "Vaš Email",
        homePageContactField2: "Poruka",
        homePageContactButton: "Pošalji",

        //FOOTER
        footerHomePage: "Početna",
        footerAboutUs: "O Nama",
        footerOurDivisions: "Naše Divizije",
        footerContactUs: "Kontakt",
        footerContactField: "Kontakt Telefon",
        footerEmailField: "Kontakt Email",
        footerLocationField: "Lokacija",
        footerWorkingTimeField: "Radno Vrijeme",
        footerWorkingTimeDays: "Pon-Pet",
        footerWorkingTime: "Pon-Pet / 08h-16h",

        //ABOUT PAGE
        aboutPageHeading1: "OD 2006...",
        aboutPageText1: "Od svog osnivanja 2006. godine, posvećeni smo pružanju najkvalitetnijih proizvoda našim kupcima. Referentna lista naših partnera i kupaca kao i statistički pokazatelji, najbolji su dokaz činjenici da je OKTUM d.o.o. jedna od vodećih kompanija u Bosni i Hercegovini i regiji, u oblastima koje se nalaze u portfoliu naše firme.",
        aboutPageHeading2: "O NAŠOJ KOMPANIJI",
        aboutPageText2_1: "Core business naše kompanije već 17 godina je prerada i trgovina agrarnim proizvodima, gdje nudimo širok asortiman proizvoda u zavisnosti od potreba naših kupaca. Blisko sarađujemo sa poljoprivrednim proizvođačima i otkupljivačima kako bismo osigurali da naši proizvodi budu najboljeg kvaliteta i da ispunjavaju naše stroge standarde.",
        aboutPageText2_2: "Osim našeg core business - a, naše poslovanje smo proširili na još tri sektora, a to su: transport, finansije i građevina.",
        aboutPageText2: "Naš primarni sektor je trgovina na veliko žitaricama, gdje nudimo širok asortiman proizvoda koji zadovoljavaju različite potrebe naših kupaca. Blisko sarađujemo sa poljoprivrednicima i dobavljačima kako bismo osigurali da su naši proizvodi najvišeg kvaliteta i da ispunjavaju naše stroge standarde održivosti i etike.\n" +
            "\n" +
            "Osim naše osnovne djelatnosti, proširili smo se i na još dva sektora – finansije i transport. Naš finansijski sektor pruža rješenja za financiranje našim klijentima, olakšavajući im kupovinu naših proizvoda. Naš transportni sektor ima desetine vlastitih kamiona, osiguravajući da se naši proizvodi bezbjedno i efikasno isporučuju kupcima širom zemlje.",
        aboutPageHeading3: "MISIJA I VIZIJA",
        aboutPageText3: "U Oktumu vjerujemo u važnost održivosti i etičke poslovne prakse. Posvećeni smo odgovornom nabavljanju naših proizvoda i radu sa našim dobavljačima kako bismo smanjili uticaj našeg poslovanja na životnu sredinu. Ponosimo se našim proizvodima i uslugama i posvećeni smo pružanju proizvoda najvišeg kvaliteta po najkonkurentnijim cijenama.\n" +
            "\n" +
            "Kao vodeća kompanija u oblasti veleprodaje i uvoza agrara, stalno inoviramo i unapređujemo naše usluge. Naš tim stručnjaka je uvijek u potrazi za novim i poboljšanim proizvodima i uslugama koje možemo ponuditi našim kupcima. Uz našu posvećenost kvalitetu, održivosti i zadovoljstvu kupaca, uvjereni smo da ćemo nastaviti da vodimo industriju dugi niz godina.",
        aboutPageText3_1: "U Oktumu vjerujemo u važnost održivosti i etičke poslovne prakse. Posvećeni smo odgovornom nabavljanju naših proizvoda i radu sa našim dobavljačima kako bismo smanjili uticaj našeg poslovanja na životnu sredinu. Ponosimo se našim proizvodima i uslugama i posvećeni smo pružanju proizvoda najvišeg kvaliteta po najkonkurentnijim cijenama.",
        aboutPageText3_2:"Kao vodeća kompanija u oblasti veleprodaje i uvoza agrara, stalno inoviramo i unapređujemo naše usluge. Naš tim stručnjaka je uvijek u potrazi za novim i poboljšanim proizvodima i uslugama koje možemo ponuditi našim kupcima. Uz našu posvećenost kvalitetu, održivosti i zadovoljstvu kupaca, uvjereni smo da ćemo nastaviti da vodimo industriju dugi niz godina.",
        aboutPageHeading4: "NAŠI REZULTATI",
        aboutPageText4: "Osnovan 2006. OKTUM je i zvanično najveća kompanija u oblasti agrara u Bosni i Hercegovini. \n" +
            "Referentna lista naših komitenata i zadovoljnih partnera je najbolji pokazatelj našeg rezultata. \n" +
            "OKTUM ima tendenciju stabilnog rasta i u druge tri divizije u svom poslovanju. \n" +
            "Kompanija je posvećena održivosti, ozbiljnosti, korektnosti i konkurentnosti na svim tržištima na kojima posluje. Sa timom stručnjaka i posvećenošću inovacijama, OKTUM konstantno ide uzlaznom putanjom i podiže poslovne ljestvice na najveći mogući nivo.",
        aboutPageText4_1: "Osnovan 2006. OKTUM je i zvanično najveća kompanija u oblasti agrara u Bosni i Hercegovini.",
        aboutPageText4_2: "Referentna lista naših komitenata i zadovoljnih partnera je najbolji pokazatelj našeg rezultata.",
        aboutPageText4_3: "OKTUM ima tendenciju stabilnog rasta i u druge tri divizije u svom poslovanju.",
        aboutPageText4_4: "Kompanija je posvećena održivosti, ozbiljnosti, korektnosti i konkurentnosti na svim tržištima na kojima posluje.",
        aboutPageText4_5: "Sa timom stručnjaka i posvećenošću inovacijama, OKTUM konstantno ide uzlaznom putanjom i podiže poslovne ljestvice na najveći mogući nivo.",

        //CONTACT PAGE
        contactPageFormHeading: "KONTAKTIRAJTE NAS",
        contactPageFormField1: "VAŠE IME I PREZIME",
        contactPageFormField2: "VAŠ EMAIL",
        contactPageFormField3: "PORUKA",
        contactPageFormButton: "POŠALJI",


        //DIVISIONS PAGE
        divisionsPageHeading: "NAŠE DIVIZIJE",
        divisionsPageAgrarCardField1: "AGRAR",
        divisionsPageAgrarCardField2: "VIŠE O AGRARU",
        divisionsPageFinanceCardField1: "FINANSIJE",
        divisionsPageFinanceCardField2: "VIŠE O FINANSIJAMA",
        divisionsPageLogisticsCardField1: "LOGISTIKA",
        divisionsPageLogisticsCardField2: "VIŠE O LOGISTICI",


        //AGRAR PAGE
        agrarPageMainHeading: "AGRAR",
        agrarPageHeading1: "AGRAR U NAŠOJ KOMPANIJI",
        agrarPageText1: "Core business naše kompanije je agrar, te on čini blizu 60% naših prihoda. \n" +
            "Naša agrar divizija posvećena je bliskoj saradnji sa poljoprivrednicima i otkupljivačima, kako bi osigurali da naši proizvodi budu najviše kvalitete i da ispunjavaju naše stroge standarde,\n" +
            "sa fokusom na kvalitetu i održivost, ponosno nudimo širok spektar poljoprivrednih proizvoda koje zadovoljavaju raznolike potrebe naših kupaca.",
        agrarPageHeading2: "KUKURUZ",
        agrarPageText2: "Blisko sarađujemo sa poljoprivrednim proizvođačima i otkupljivačima kako bi osigurali da naš proizvod bude najviše kvalitete i da zadovoljava kako naše, tako i interne standarde naših komitenata.\n" +
            "Na polju kukuruza, prema zvaničnim podacima relevantnog udruženja žita Srbije, već dugi niz godina naša kompanija je NAJVEĆI trgovac u Bosni i Hercegovini.",
        agrarPageHeading3: "SOJA",
        agrarPageText3: "OKTUM je i u ovom segmentu najveći dobavljač visokokvalitetne sojine sačme i ostalih sojinih proizvoda, za preduzeća, trgovce i fabrike kako u Bosni i Hercegovini tako i u Jugoistočnoj Evropi.\n" +
            "Vodeći se kvalitetom, naša kompanija već dugi niz godina sarađuje sa provjerenim dobavljačima sojinog zrna, kako u regiji tako i na prostoru Južne Amerike i primjenjuje rigorozne procese testiranja i inspekcije kako bi osigurala da svi proizvodi zadovoljavaju naše stroge standarde.\n" +
            "OKTUM također nudi fleksibilne mogućnosti finansiranja i efikasna logistička rješenja, kako bi osigurala pravovremeno isporuku svojim klijentima.",
        agrarPageHeading5: "ULJA",
        agrarPageText5: "U kooperaciji sa našim partnerima, jedni smo od najvećih dobavljača ulja za fabrike hrane, konditorske fabrike, fabrike stočne hrane kao i mesne industrije u regionu Zapadnog Balkana. \n" +
            "U portfoliu naših ulja nalaze se: rafinisano i nerafinisano suncokretovo ulje, ulje od uljane repice, maslinovo, kukuruzno te sojino ulje.\n" +
            "Zbog osjetljivosti proizvodnje naših komitenata, ulja koja nudimo prolaze visok stepen kontrole te s toga posjeduju potrebne međunarodne certifikate i standarde.",
        agrarPageHeading6: "MINERALNA ĐUBRIVA",
        agrarPageText6: "Razumijemo da kvalitetno đubrivo igra ključnu ulogu u postizanju zdravih i produktivnih usjeva, stoga se fokusiramo na pružanje najboljih đubriva koja zadovoljavaju potrebe različitih vrsta usjeva i tla. Naša ponuda đubriva obuhvata razne formulacije i sastave, uključujući organska đubriva, mineralna đubriva i mikroelemente.",
        agrarPageHeading4: "ŽITARICE",
        agrarPageText4: "Pored pomenutih žitarica, OKTUM se intenzivno bavi uzgojem, preradom i prodajom pšenice, ječma i suncokreta.\n" +
            "U kooperaciji sa poljoprivrednim proizvođačima i našim dugogodišnjim partnerima na ovom polju bilježimo konstantan rast i povećanje produktivnosti. \n" +
            "Referentna lista naših komitenata najbolji je dokaz kvalitete, cijene i ozbiljnosti u poslovanju koje OKTUM nudi.",
        //FINANCE PAGE
        financePageMainHeading: "FINANSIJE",
        financePageHeading1: "FINANSIJE U NAŠOJ KOMPANIJI",
        financePageText1: "U OKTUM-u razumijemo da upravljanje tokom novca može biti izazov za poslovne subjekte svih veličina. Zato nudimo fleksibilne opcije finansiranja kako bismo pomogli našim klijentima da efikasno upravljaju svojim finansijama.",
        financePageHeading2: "ZAJMOVI, FINANSIRANJE I FAKTORING",
        financePageText2: "Naše finansijske opcije su prilagođene jedinstvenim potrebama svakog od naših klijenata i uključuju opcije kao što su kratkoročni zajmovi, trgovinsko finansiranje i faktoring faktura.",
        financePageHeading5: "FINANSIRANJE GRAĐEVISNKIH PROJEKATA",
        financePageText5: "OKTUM pruža fleksibilna finansijska rješenja za podršku finansiranju građevinskih objekata. Razumijemo da građevinski projekti zahtijevaju značajna finansijska ulaganja, stoga smo tu da pomognemo našim klijentima da ostvare svoje građevinske ambicije. OKTUM također ima bogato iskustvo u saradnji sa građevinskim preduzećima, investitorima i drugim relevantnim akterima u industriji. To nam omogućava da pružimo strateške savjete i podršku našim klijentima kako bi ostvarili uspješne građevinske projekte. OKTUM također vrši investiranje u vlastite projekte i vlastiti real estate business.",
        financePageHeading6: "FINANSIRANJE GREENFIELD INVESTICIJA I STARTUP-A",
        financePageText6: "Naša finansijska podrška za greenfield investicije i startup-e obuhvata različite opcije kao što su investicioni krediti, kapitalni investitori, grantovi i drugi oblici finansiranja. Naš cilj je pružiti finansijsku podršku koja omogućava našim klijentima da započnu svoje poslovanje ili prošire svoje poslovanje na novim tržištima. Naša fleksibilnost, stručnost i posvećenost klijentima čine nas idealnim partnerom za finansiranje greenfield investicija i startup-a. Bez obzira na vrstu posla ili industrijju, OKTUM je ovdje da pruži finansijska rješenja koja podržavaju inovacije, razvoj i uspjeh u poslovanju.",
        financePageHeading3: "PRILAGOĐENA RJEŠENJA",
        financePageText3: "Naš tim iskusnih profesionalaca posvećen je pružanju izuzetne usluge klijentima, pomaganju klijentima da se snalaze u složenom svijetu finansija i pružanju prilagođenih rješenja koja zadovoljavaju njihove specifične potrebe. Blisko sarađujemo sa našim klijentima kako bismo identifikovali najbolje opcije finansiranja koje su u skladu sa njihovim poslovnim ciljevima i budžetom.",
        financePageHeading4: "PODRŠKA I SMJERNICE",
        financePageText4: "Naša posvećenost našim klijentima ide dalje od finansijskih i logističkih rešenja. Pružamo stalnu podršku i smjernice kako bismo pomogli našim klijentima da razviju svoje poslovanje i postignu svoje finansijske ciljeve. U OKTUM-u smo ponosni na izgradnju dugoročnih odnosa sa našim klijentima, zasnovanih na povjerenju, transparentnosti i zajedničkom uspjehu.",

        //LOGISTICS PAGE
        logisticsPageMainHeading: "LOGISTIKA",
        logisticsPageHeading1: "LOGISTIKA U NAŠOJ KOMPANIJI",
        logisticsPageText1: "U OKTUM-u razumijemo kritičnu ulogu koju logistika igra u svakom poslu. Zato nudimo sveobuhvatna logistička rješenja kako bismo pomogli našim klijentima da efikasno upravljaju svojim lancem opskrbe. Naše logističke usluge uključuju usluge upravljanja zalihama, skladištenja, transporta i distribucije, a sve su dizajnirane da pomognu našim klijentima da optimiziraju svoje poslovanje i poboljšaju svoj rezultat.",
        logisticsPageHeading2: "TRANSPORT",
        logisticsPageText2: "Blisko sarađujemo s našim klijentima kako bismo razumjeli njihove specifične logističke potrebe i razvili prilagođena rješenja koja zadovoljavaju te potrebe. Naš tim iskusnih logističkih profesionalaca posjeduje stručnost i resurse da osigura da se proizvodi naših klijenata transportuju i skladište bezbijedno i efikasno, uz pravovremenu isporuku i precizno praćenje.",
        logisticsPageHeading3: "BEZBJEDNO I NA VRIJEME",
        logisticsPageText3: "Posjedujemo flotu od 78 voznih jedinica opremljenih najsavremenijim sistemima kako bismo osigurali da se naši proizvodi kao i proizvodi naših komitenata transportuju bezbjedno i na vrijeme. Naše usluge skladištenja uključuju skladištenje sa kontroliranom temperaturom i ostalim uslovima kao i sigurno upravljanje zalihama, a sve u cilju zadovoljstva i efikasnosti svakog našeg klijenta.",
        logisticsPageHeading4: "LOGISTIČKA RJEŠENJA",
        logisticsPageText4: " U OKTUM-u smo posvećeni pružanju izuzetne korisničke usluge i osiguravanju da naši klijenti imaju podršku koja im je potrebna za poslovni uspjeh. Naša logistička rješenja su dizajnirana da pomognu našim klijentima da pojednostave svoj lanac nabavke, smanje troškove i poboljšaju svoju ukupnu operativnu efikasnost. Vjerujemo da pružanjem pouzdanih i isplativih logističkih usluga možemo pomoći našim klijentima da ostvare svoje poslovne ciljeve i uspiju u svojim djelatnostima.",

    },


    english: {

        //GENERAL
        readMore: "Click on the card to read more!",
        successSubmit: "Message successfully sent!",

        //HOME PAGE
        homeButton: "HOME",
        aboutButton: "ABOUT US",
        aboutButton1: "About Company",
        aboutButton2: "Mission and Vision",
        aboutButton3: "Our Results",
        divisionsButton: "OUR DIVISIONS",
        allDivisionsButton: "ALL DIVISIONS",
        divisionsButton1: "Agrar",
        divisionsButton2: "Finance",
        divisionsButton3: "Logistics",
        contactButton: "CONTACT",
        contactButton1: "Contact Us",
        contactButton2: "Location",
        langButton: "LANGUAGE",
        button: "Bosanski",
        aboutHeading: "ABOUT OUR COMPANY",
        aboutText: "Since our establishment in 2006, we have been dedicated to providing the highest quality products to our customers. The reference list of our partners and customers, as well as statistical indicators, are the best proof of the fact that OKTUM d.o.o. one of the leading companies in Bosnia and Herzegovina and the region, in the areas that are in our company's portfolio.",
        homePageAboutButton: "MORE ABOUT US",
        homePageDivisions: "OUR DIVISIONS",
        homePageDivisionsHeading: "DIVISIONS",
        homePageDivisionsText: "Our primary sector is wholesale trading of cereals. In addition to our primary sector, we have expanded into two more - finance and transport.",
        homePageDivisionsButton: "ALL DIVISIONS",
        homePageAgrarHeading: "AGRAR",
        homePageAgrarText: "We offer a wide range of products to meet the diverse needs of our customers.",
        homePageFinanceHeading: "FINANCE",
        homePageFinanceText: "Finance sector provides financing solutions to our customers, making it easier for them to purchase our products.",
        homePageLogisticsHeading: "LOGISTICS",
        homePageLogisticsText: "Our transport sector consists of dozens of our trucks, ensuring that our products are delivered safely and efficiently to customers across the country.",
        homePageContactHeading: "CONTACT US",
        homePageContactField1: "Your Email",
        homePageContactField2: "Message",
        homePageContactButton: "SEND",

        //FOOTER
        footerHomePage: "Home",
        footerAboutUs: "About Us",
        footerOurDivisions: "Our Divisions",
        footerContactUs: "Contact",
        footerContactField: "Contact Number",
        footerEmailField: "Contact Email",
        footerLocationField: "Location",
        footerWorkingTimeField: "Working Hours",
        footerWorkingTimeDays: "Mon-Fri",
        footerWorkingTime: "Mon-Fri / 08h-16h",

        //ABOUT PAGE
        aboutPageHeading1: "SINCE 2006...",
        aboutPageText1: "Since our establishment in 2006, we have been dedicated to providing the highest quality products to our customers. The reference list of our partners and customers, as well as statistical indicators, are the best proof of the fact that OKTUM d.o.o. one of the leading companies in Bosnia and Herzegovina and the region, in the areas that are in our company's portfolio.",
        aboutPageHeading2: "ABOUT OUR COMPANY",
        aboutPageText2: "Our primary sector is wholesale trading of cereals, where we offer a wide range of products to meet the diverse needs of our customers. We work closely with farmers and suppliers to ensure that our products are of the highest quality and that they meet our strict sustainability and ethical standards.\n" +
            "\n" +
            "In addition to our core business, we have expanded into two other sectors - finance and transport. Our finance sector provides financing solutions to our customers, making it easier for them to purchase our products. Our transport sector boasts dozens of our own trucks, ensuring that our products are delivered safely and efficiently to customers across the country.",
        aboutPageText2_1: "The core business of our company has been the processing and trade of agricultural products for 17 years, where we offer a wide range of products depending on the needs of our customers. We work closely with agricultural producers and buyers to ensure that our products are of the best quality and meet our strict standards.",
        aboutPageText2_2: "In addition to our core business, we have expanded our operations to three more sectors: transport, finance and construction.",
        aboutPageHeading3: "MISSION AND VISION",
        aboutPageText3: "At OKTUM, we believe in the importance of sustainability and ethical business practices. We are committed to sourcing our products responsibly and working with our suppliers to reduce the environmental impact of our operations. We take pride in our products and services and are committed to providing the highest quality products at the most competitive prices.\n" +
            "\n" +
            "As a leading company in the industry, we are constantly innovating and improving our services. Our team of experts is always on the lookout for new and improved products and services to offer our customers. With our commitment to quality, sustainability, and customer satisfaction, we are confident that we will continue to lead the industry for many years to come.",
        aboutPageText3_1: "At OKTUM, we believe in the importance of sustainability and ethical business practices. We are committed to sourcing our products responsibly and working with our suppliers to reduce the environmental impact of our operations. We take pride in our products and services and are committed to providing the highest quality products at the most competitive prices.",
        aboutPageText3_2: "As a leading company in the industry, we are constantly innovating and improving our services. Our team of experts is always on the lookout for new and improved products and services to offer our customers. With our commitment to quality, sustainability, and customer satisfaction, we are confident that we will continue to lead the industry for many years to come.",
        aboutPageHeading4: "OUR RESULTS",
        aboutPageText4: "Founded in 2006, OKTUM is officially the largest company in the field of agriculture in Bosnia and Herzegovina.\n" +
            "The reference list of our clients and satisfied partners is the best indicator of our results.\n" +
            "OKTUM has a tendency of stable growth in the other three divisions of its business.\n" +
            "The company is committed to sustainability, seriousness, fairness and competitiveness in all markets where it operates. With a team of experts and a commitment to innovation, OKTUM is constantly on an upward trajectory and raises the business ladder to the highest possible level.",
        aboutPageText4_1: "Established in 2006, OKTUM is a leading company in the trading and wholesale of cereals, corn, soybean, wheat, rye, and barley",
        aboutPageText4_2: "OKTUM is a major player in the industry and is committed to providing the highest quality products at competitive prices",
        aboutPageText4_3: "OKTUM has expanded into three other sectors - finance, transport and construction.",
        aboutPageText4_4: "The company is committed to sustainability and ethical business practices, working closely with suppliers to ensure the responsible sourcing of its products",
        aboutPageText4_5: "With a team of experts and a commitment to innovation, OKTUM is poised to continue leading the industry and providing the best products and services to its customers",
        //CONTACT PAGE
        contactPageFormHeading: "CONTACT US",
        contactPageFormField1: "YOUR NAME AND SURNAME",
        contactPageFormField2: "YOUR EMAIL",
        contactPageFormField3: "MESSAGE",
        contactPageFormButton: "SEND",


        //DIVISIONS PAGE
        divisionsPageHeading: "OUR DIVISIONS",
        divisionsPageAgrarCardField1: "AGRAR",
        divisionsPageAgrarCardField2: "MORE ABOUT AGRAR",
        divisionsPageFinanceCardField1: "FINANCE",
        divisionsPageFinanceCardField2: "MORE ABOUT FINANCE",
        divisionsPageLogisticsCardField1: "LOGISTICS",
        divisionsPageLogisticsCardField2: "MORE ABOUT LOGISTICS",


        //AGRAR PAGE
        agrarPageMainHeading: "AGRAR",
        agrarPageHeading1: "AGRAR IN OUR COMPANY",
        agrarPageText1: "The core business of our company is agriculture, which accounts for close to 60% of our revenues.\n" +
            "Our agrarian division is committed to working closely with farmers and buyers to ensure that our products are of the highest quality and meet our strict standards,\n" +
            "with a focus on quality and sustainability, we proudly offer a wide range of agricultural products that meet the diverse needs of our customers.",
        agrarPageHeading2: "CORN",
        agrarPageText2: "We work closely with agricultural producers and buyers to ensure that our product is of the highest quality and meets both our and our customers' internal standards.\n" +
            "In the corn field, according to the official data of the relevant grain association of Serbia, our company has been the BIGGEST trader in Bosnia and Herzegovina for many years.",
        agrarPageHeading3: "SOYBEAN",
        agrarPageText3: "OKTUM is also in this segment the largest supplier of high-quality soybean meal and other soybean products, for companies, traders and factories both in Bosnia and Herzegovina and in Southeast Europe.\n" +
            "Driven by quality, our company has been working with proven soybean suppliers for many years, both in the region and in South America, and applies rigorous testing and inspection processes to ensure that all products meet our strict standards.\n" +
            "OKTUM also offers flexible financing options and efficient logistics solutions to ensure timely delivery to its customers.",
        agrarPageHeading5: "OIL",
        agrarPageText5: "In cooperation with our partners, we are one of the largest suppliers of oil for food factories, confectionery factories, animal feed factories and the meat industry in the Western Balkans region.\n" +
            "Our oil portfolio includes: refined and unrefined sunflower oil, rapeseed oil, olive oil, corn oil, and soybean oil.\n" +
            "Due to the sensitivity of our clients' production, the oils we offer undergo a high degree of control and therefore possess the necessary international certificates and standards.",
        agrarPageHeading6: "FERTILIZERS",
        agrarPageText6: "We understand that quality fertilizer plays a key role in achieving healthy and productive crops, so we focus on providing the best fertilizers that meet the needs of different types of crops and soils. Our fertilizer offer includes various formulations and compositions, including organic fertilizers, mineral fertilizers and microelements.",
        agrarPageHeading4: "GRAIN",
        agrarPageText4: "In addition to the mentioned grains, OKTUM is intensively engaged in growing, processing and selling wheat, barley and sunflower.\n" +
            "In cooperation with agricultural producers and our long-term partners in this field, we record constant growth and increased productivity.\n" +
            "The reference list of our clients is the best proof of the quality, price and seriousness in business that OKTUM offers.",
        //FINANCE PAGE
        financePageMainHeading: "FINANCE",
        financePageHeading1: "FINANCE IN OUR COMPANY",
        financePageText1: "At OKTUM, we understand that managing cash flow can be a challenge for businesses of all sizes. That's why we offer flexible financing options to help our clients manage their finances effectively.",
        financePageHeading2: "LOANS, FINANCING AND INVOICING",
        financePageText2: "Our financing solutions are tailored to meet the unique needs of each of our clients and include options such as short-term loans, trade financing, and invoice factoring.",
        financePageHeading5: "CONSTRUCTION PROJECTS FINANCING",
        financePageText5: "OKTUM provides flexible financial solutions to support the financing of construction facilities. We understand that construction projects require significant financial investment, so we are here to help our clients achieve their construction ambitions. OKTUM also has rich experience in cooperation with construction companies, investors and other relevant actors in the industry. This enables us to provide strategic advice and support to our clients to achieve successful construction projects. OKTUM also invests in its own projects and its own real estate business.",
        financePageHeading6: "FINANCING OF GREENFIELD INVESTMENTS AND STARTUPS",
        financePageText6: "Our financial support for greenfield investments and startups includes various options such as investment loans, capital investors, grants and other forms of financing. Our goal is to provide financial support that enables our clients to start their business or expand their business in new markets. Our flexibility, expertise and commitment to clients make us the ideal partner for financing greenfield investments and startups. Regardless of the type of business or industry, OKTUM is here to provide financial solutions that support innovation, development and business success.",
        financePageHeading3: "CUSTOM SOLUTIONS",
        financePageText3: "Our team of experienced professionals is dedicated to providing exceptional customer service, helping clients navigate the complex world of finance, and delivering customized solutions that meet their specific needs. We work closely with our clients to identify the best financing options that align with their business goals and budget.",
        financePageHeading4: "SUPPORT AND GUIDELINES",
        financePageText4: "Our commitment to our clients goes beyond financing and logistics solutions. We provide ongoing support and guidance to help our clients grow their businesses and achieve their financial goals. At OKTUM, we pride ourselves on building long-term relationships with our clients, based on trust, transparency, and mutual success.",

        //LOGISTICS PAGE
        logisticsPageMainHeading: "LOGISTICS",
        logisticsPageHeading1: "LOGISTICS IN OUR COMPANY",
        logisticsPageText1: "At OKTUM, we understand the critical role that logistics plays in any business. That's why we offer comprehensive logistics solutions to help our clients manage their supply chain efficiently. Our logistics services include inventory management, warehousing, transportation, and distribution services, all designed to help our clients optimize their operations and improve their bottom line.",
        logisticsPageHeading2: "TRANSPORT",
        logisticsPageText2: "We work closely with our clients to understand their specific logistics needs and develop customized solutions that meet those needs. Our team of experienced logistics professionals has the expertise and resources to ensure that our clients' products are transported and stored safely and efficiently, with timely delivery and accurate tracking.",
        logisticsPageHeading3: "SAFE AND ON TIME",
        logisticsPageText3: "We have a fleet of 78 vehicles equipped with state-of-the-art systems to ensure that our products and those of our customers are transported safely and on time. Our warehousing services include temperature-controlled storage and other conditions as well as secure inventory management, all aimed at the satisfaction and efficiency of each of our clients.",
        logisticsPageHeading4: "LOGISTICS SOLUTIONS",
        logisticsPageText4: "At OKTUM, we are committed to providing exceptional customer service and ensuring that our clients have the support they need to succeed. Our logistics solutions are designed to help our clients streamline their supply chain, reduce costs, and improve their overall operational efficiency. We believe that by providing reliable and cost-effective logistics services, we can help our clients achieve their business goals and succeed in their respective industries.",

    }
};